<template>
  <div>
  <div class="top-navigation-entire-wrapper ext" ref="navWrapper">
    <div class="flash-message" @click="hideFlashMessage" v-touch:swipe-right="hideFlashMessage"  v-if="showFlashMessage">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM17.508 6.696L9.6 14.604L6.492 11.508L4.8 13.2L9.6 18L19.2 8.4L17.508 6.696Z" fill="#FFFFFC"/>
      </svg>
      {{'Your Email Has Been Sent'}}
    </div>
    <div id="hl-nav-wrapper">
    <div class="top-navigation-universal" :style="defaultStyle">
    <div class="logo-nav-wrapper2">
      <img class="logo" @click="goHome" src="https://hawaiilife-app-assets.s3.us-west-2.amazonaws.com/search-navigation/HL-Logo-Brown-Homepage.png" alt="" />
      <img  @click="goHome" class="logo-full-screen-modal-text" src="https://hawaiilife-app-assets.s3.us-west-2.amazonaws.com/search-navigation/HL-Logo-Brown-Text-From-Homepage.svg" alt="" />
    </div>
    <div class="gap-grow"></div>
    <div class="search">
      <div class="search-wrapper-with-button" v-if="showUniversalSearchComponent">
        <UniversalSearchComponent :agent-or-slug-agent="agentOrSlugAgent" :external="true"></UniversalSearchComponent>
      </div>
    </div>
    <div class="gap-grow"></div>
    <div class="nav-test">
      <div id="main-cta-support-wrapper-mobile" class="cta-support-wrapper for-mobile" @click="showContactOverlay = !showContactOverlay">
        <div class="cta-support" :class="{'active':showContactOverlay}">
          <div v-if="!agentOrSlugAgent" class="text-in-cta">
            Speak with<br />
            <span class="agent-name">Hawai'i Life</span>
          </div>
          <div v-if="agentOrSlugAgent" class="text-in-cta">
            Speak with<br />
            <span class="agent-name">{{agentFirstName}}</span>
          </div>
          <img v-if="!agentOrSlugAgent" src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/lead-team/Kahea_Zietz_300px-270x270.jpg" alt="" />
          <img v-if="agentOrSlugAgent" :src="agentProfileImageURL" alt="" />
          <ContactOptions :open-contact-modal="doShowContactAgentModal" :use-contact-modal-external="true" :hide-contact-overlay="hideContactOverlay" :agent-or-slug-agent="agentOrSlugAgent" :agent-designation="agentDesignation" :agent-first-name="agentFirstName" :agent="agent" v-if="showContactOverlay"></ContactOptions>
        </div>
      </div>
      <div class="dropdown-show-mobile" @click.stop="showNavigation">
        <NavIcon></NavIcon>
      </div>
      <div class="dropdown-href-wrappers">
        <div class="ce-cta-wrapper-desktop">
          <div id="circle-cta" class="circle-cta">
            <div class="circle" style="animation-delay: 0s"></div>
            <div class="circle-cta-inner"></div>
            <span>
              <svg style="margin-left:-5px; display:inline-block;" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-up" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 5l0 14" fill="white" />
  <path d="M16 9l-4 -4" fill="white" />
  <path d="M8 9l4 -4" fill="white" />
</svg>

Click to<br /> communicate.</span>
          </div>
          <div id="main-cta-support-wrapper" :class="{'active':showContactOverlay}" class="cta-support-wrapper" @click="showContactOverlay = !showContactOverlay">
            <div class="cta-support">
              <div v-if="!agentOrSlugAgent" class="text-in-cta">
                Speak with<br />
                <span class="agent-name">Hawai'i Life</span>
              </div>
              <div v-if="agentOrSlugAgent" class="text-in-cta">
                Speak with<br />
                <span class="agent-name">{{agentFirstName}}</span>
              </div>
              <img v-if="!agentOrSlugAgent" src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/lead-team/Kahea_Zietz_300px-270x270.jpg" alt="" />
              <img v-if="agentOrSlugAgent" :src="agentProfileImageURL" alt="" />
              <ContactOptions :hide-contact-overlay="hideContactOverlay" :use-contact-modal-external="true" :show-contact-modal-external="doShowContactAgentModal" :agent-designation="agentDesignation" :agent-or-slug-agent="agentOrSlugAgent" :agent="agent" :agent-first-name="agentFirstName" v-if="showContactOverlay"></ContactOptions>
            </div>
          </div>
        </div>
        <div class="company-nav-links" v-for="(navs,name) of defaultNav.nav" :class="name">
          <a @click.stop="doShowNav(name)" :class="name" class="nav-link">{{name}}</a>
          <div :class="{hidden:!navsShown[name]}" class="menu child">
            <span><CloseX :close="() => { navsShown[name] = false; $forceUpdate()}"></CloseX></span>
            <div v-for="nav of navs">
              <a @click="navClick(nav)" :class="nav.class" :href="nav.href">{{nav.name}}</a>
              <div v-if="subNavsShown[nav.name] && nav && nav.children" class="subnav-menu">
                <a v-for="kid of nav.children" :href="getSubNavLink(kid.href,name,agentOrSlugAgent)" :class="kid.class">{{kid.name}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="show-nav-icon-medium" @click.stop="showNavigation">
          <NavIcon></NavIcon>
        </div>
        <div class="trigger-for-nav-dropdown" v-if="loggedIn">
          <div class="hl-really-hidden-mobile first-name" @click.stop="doShowNav('userMenu')">{{firstName}} </div>
          <div @click.stop="doShowNav('userMenu')" class="circle-around-initials">
            <span>{{initials}}</span>
            <div class="dropdown-show-navigation" @click.stop="showNavigation">
              <NavIcon></NavIcon>
            </div>
          </div>
          <div class="menu" :class="{hidden:!navsShown['userMenu']}">
            <CloseX :close="() => { navsShown['userMenu'] = false; $forceUpdate(); }"></CloseX>
            <div class="menu-nav-link" v-if="loggedIn">
              <a @click.stop="showAccountPanel">
              <DataBrick></DataBrick>
            <span>Overview</span></a>
            </div>

            <div class="menu-nav-link" v-if="loggedIn">
              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1 15.55L10 15.65L9.89 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 3 9.07 4.36H10.93C11.46 3 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55ZM14.5 0C12.76 0 11.09 0.81 10 2.08C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.41 0 5.5C0 9.27 3.4 12.36 8.55 17.03L10 18.35L11.45 17.03C16.6 12.36 20 9.27 20 5.5C20 2.41 17.58 0 14.5 0Z" fill="#262626"/>
              </svg>
              <a @click.stop="showFavoritesPanel">Favorites</a>
            </div>
            <div class="menu-nav-link" v-if="loggedIn">
              <a @click.stop="showSavedSearchesPanel">
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 19H11C11 20.1 10.1 21 9 21C7.9 21 7 20.1 7 19ZM18 17V18H0V17L2 15V9C2 5.9 4 3.2 7 2.3V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.3C14 3.2 16 5.9 16 9V15L18 17ZM14 9C14 6.2 11.8 4 9 4C6.2 4 4 6.2 4 9V16H14V9Z" fill="#262626"/>
              </svg>
              <span>Saved Searches</span></a>
            </div>
            <div class="menu-nav-link" v-if="loggedIn">
              <a @click.stop="showAccountPanel">
                <ProfileGearIcon></ProfileGearIcon>
                <span>
                Profile
              </span>
                </a>
            </div>
            <div class="menu-nav-link" v-if="showAgentAdmin">
              <a @click="navigateAgentAdmin"> <ShieldIcon></ShieldIcon>
                <span>Your Admin</span></a>
            </div>
            <div class="menu-nav-link" v-if="showAdmin">
              <a @click="navigateAdmin"> <ShieldIcon></ShieldIcon>
                <span>Site Admin</span></a>
            </div>
            <div class="menu-nav-link" v-if="loggedIn">
              <a class="sign-out" @click="logout">Sign Out</a>
            </div>
            <div class="menu-nav-link">
              <span class="grey">We're here. Call 800-370-3848</span>
            </div>
          </div>
        </div>
        <div v-if="!loggedIn" class="company-nav-links">
          <a  @click.stop="doShowLogin" class="nav-link">Sign In</a>
        </div>
      </div>

    </div>
      <div class="only-mobile" v-if="navsShown['userMenu']">
      <top-nav-external :close-user-nav="hideUserMenu"></top-nav-external>
      </div>
      </div>
  </div>
    </div>
    <Modal v-if="showContactAgentModal" :no-x="true" :additional-class="'tall'" :modal-class="'auto-height'" :close-modal="hideEmailAgentModal">
      <div class="contact-agent" :class="{external:true}">
        <CloseX :close="hideEmailAgentModal"></CloseX>
        <h2>Email {{ contactAgentName }}</h2>
        <div class="hl-form">
          <div>
            <HLInput :set-value="setContactAgentName" :value="contactAgent.name" additional-class="full taller in-modal fourtyheight" :force-invalid="errorAgentContactName"  :form-validation-fn="validateSendAgentEmail"  label="Your Name" :required="true"></HLInput>
          </div>
          <div>
            <HLInput :set-value="setContactAgentEmail" :value="contactAgent.email" additional-class="full taller in-modal fourtyheight" :force-invalid="errorAgentContactEmail"  :form-validation-fn="validateSendAgentEmail"  label="Enter Email" :required="true"></HLInput>
          </div>
          <div>
            <HLInput :set-value="setContactAgentPhone" :value="contactAgent.phone" additional-class="full taller in-modal fourtyheight" :form-validation-fn="validateSendAgentEmail" :type="'text'" label="Enter Your Phone" :required="true"></HLInput>
          </div>
          <div>
            <HLInput :set-value="setContactAgentMessage" :value="contactAgent.message" additional-class="full taller in-modal fourtyheight" :form-validation-fn="validateSendAgentEmail" :type="'text'" label="Enter Your Message" :required="true"></HLInput>

          </div>

          <a :class="{disabled:!contactAgentEmailValid}" @click="sendContactAgentEmail" class="hl-btn">
            Send Email
          </a>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import BlockingCTABox from "../components/BlockingCTABox";
import {mapGetters} from "vuex";
import axios from "axios";
import ContactOptions from '../components/ContactOptions.vue'
import UniversalSearchComponent from "../components/UniversalSearchComponent.vue";
import CloseX from '../components/close-x.vue';
import Modal from '../components/Modal.vue';
import HLInput from "../components/HLInput.vue";
import NavIcon from "../components/NavIcon.vue";
import TopNavExternal from '../components/TopNavExternal.vue';
import ProfileGearIcon from "../components/SVG/Icons/ProfileGearIcon.vue";
import ShieldIcon from "../components/SVG/Icons/ShieldIcon.vue";
import DataBrick from "../components/SVG/Icons/DataBrick.vue";
import localStorage from '../../src/helpers/localstorage.js';
import sendAgentEmail from '../helpers/sendAgentEmail.js';
export default {
  name: "TopNavUniversal",
  props:{
    layout:{
      type:String,
      default(){
        return 'default';
      }
    },
    defaultStyle:{
      type:Object,
      default(rawProps) {
        if(rawProps) return rawProps;
      }
    }
  },
  components:{
    DataBrick,
    ShieldIcon,
    ProfileGearIcon,
    BlockingCTABox,
    ContactOptions,
    UniversalSearchComponent,
    Modal,
    HLInput,
    CloseX,
    NavIcon,
    TopNavExternal,
  },
  mounted(){
    let referrerIsHawaiiLife = false;

    this.measureNav();
    setTimeout(() => {
      this.measureNav();
    },2000);
    setTimeout(() => {
    },10000);
      if(console){
        if(document){
          //log the referrer
          if(document.referrer){
            console.log('Referrer: ', document.referrer);
            //does document.referrer start with a known domain
            let knownDomains = ['hawaiilife.com'];
            let domain = new URL(document.referrer).hostname;
            if(knownDomains.includes(domain)){
              referrerIsHawaiiLife = true;
            }

          }else{
            console.log('No referrer found');
          }
        }
      }



    window.HL_SHOW_EMAIL_HAWAII_LIFE_MODAL = () => {
      this.doShowContactAgentModal('hawaiilife');
    };
    window.HL_TRIGGER_LIVE_CHAT = () => {
      window.embedded_svc.bootstrapEmbeddedService();
      document.querySelector('.embeddedServiceHelpButton').click();
      setTimeout(() => {
        this.showLoadingLiveChat = false;
        this.hideContactOverlay();
      }, 5000);
    };
      if (window.HL_NAV_ADVISOR_SLUG_DATA && !referrerIsHawaiiLife) {
        if(!localStorage.fetch('hawaiilife_referrer_agent')) {
          let data = window.HL_NAV_ADVISOR_SLUG_DATA;
          data.direct_path = data.root_path;
          localStorage.put('hawaiilife_referrer_agent', window.HL_NAV_ADVISOR_SLUG_DATA);
        }
      }
  },
  updated(){
    this.measureNav();
  },
  methods:{
    goHome(){
      window.location = '/';
    },
    getSubNavLink(url, label,agentOrSlugAgent = null){
      if(label == 'Real Estate' && url && url.startsWith('/listings')){
        if(agentOrSlugAgent){
          if(agentOrSlugAgent.root_path) return agentOrSlugAgent.root_path + url;
          if(agentOrSlugAgent.direct_path) return agentOrSlugAgent.direct_path + url;
        }
      }
      return url;
    },
    hideUserMenu(){
      this.navsShown['userMenu'] = false;
      this.$forceUpdate();
    },
    hideFlashMessage(){
      this.showFlashMessage = false;
    },
    navigateAgentAdmin(){
      window.location = '/agent_tools';
    },
    navigateAdmin(){
      window.location = '/admin';
    },
    doShowContactAgentModal(sender = null){
      if(sender === 'hawaiilife'){
        this.contactAgentName = 'Hawaii Life';
        this.contactAgentSender = null;
        this.contactAgentId = 829;
      }else{
        console.log('sender');
        this.contactAgentSender = sender.email;
        this.contactAgentName = sender.user_name;
        this.contactAgentId = sender.id;
      }
      this.showContactAgentModal = true;
    },
    async sendContactAgentEmail(){

      let data = {
        name:this.contactAgent.name,
        email:this.contactAgent.email,
        phone:this.contactAgent.phone,
        comments:this.contactAgent.message,
        "source_type": "Advisor Index Page",
        source_url:window.location.href,
      };
      if(this.contactAgentSender !== 'hawaiilife'){
        data.advisor_id = this.contactAgentId
      }
      let req = {
        name:data.name,
        phone:data.phone,
        email:data.email,
        comments:data.comments,
        source_url:data.source_url,
        source_type:data.source_type,
        source_description:data.source_description ? data.source_description : 'Header CTA',
        advisor_id: data.advisor_id
      };
      sendAgentEmail(req);
      const config = {
        headers: {
          "accept": "application/json",
          "cache-control": "no-cache",
          "content-type": "application/json;charset=UTF-8",
          "pragma": "no-cache",
        }
      };
      this.showFlashMessage = true;
      setTimeout(() => {
        this.showFlashMessage = false;
      },5000);

      this.contactAgent.comments = null;
      this.showContactAgentModal = false;
      await axios.post("https://www.hawaiilife.com/api/v1/leads", req, config)
      //console.log('we are doing this');
      /**
       *
       */
    },
    validateSendAgentEmail() {
      if(this.contactAgent.name && this.contactAgent.email && this.contactAgent.phone && this.contactAgent.message) {
        this.contactAgentEmailValid = true;
      }else{
        this.contactAgentEmailValid = false;
      }
    },
    setContactAgentName(e) {
      this.contactAgent.name = e;
    },
    setContactAgentEmail(e) {
      this.contactAgent.email = e;
    },
    setContactAgentPhone(e) {
      this.contactAgent.phone = e;
    },
    setContactAgentMessage(e) {
      this.contactAgent.message = e;
    },
    hideEmailAgentModal() {
      this.showContactAgentModal = false;
    },
    forceUpdateMetadata() {
      this.$store.dispatch('listings/updateUrl', false);
      this.$store.dispatch('metadata/setMetaDataFromStore');
    },
    measureNav(){
      //const r = this.$refs.navWrapper;
      //this.$store.commit('ui/setNavHeight',r.clientHeight);
    },
    chooseAccountSection(va){
      this.$store.commit('ui/hideNav');
      this.$store.commit('ui/setAccountSection',va);
      if(va == 'Favorites'){
        window.location = '/account/favorites';
        return;
        history.pushState(null, null, '/account/favorites');
      }
      if(va == 'Saved Searches'){
        window.location = '/account/saved-searches'
        return;
        history.pushState(null, null, '/account/saved-searches');
      }
      if(va == 'Settings'){
        window.location = '/account/settings';
        return;
        history.pushState(null, null, '/account/settings');
      }
      if(va == 'Overview'){
        window.location = 'account/overview';
        return;
        history.pushState(null, null, '/account/overview');
      }
    },
    showFavoritesPanel(){
      this.$store.commit('ui/hideNav');
      this.$store.dispatch('ui/doOpenFavoritesPanel');
      if(!this.mapComponentPresent){
        window.location = '/account/favorites';
        return;
      }
      this.navsShown['userMenu'] = false;
      this.$router.replace('/account/favorites');
    },
    showSavedSearchesPanel(){
      if(!this.mapComponentPresent) {
        window.location = '/account/saved-searches';
        return;
      };
      this.$store.commit('ui/hideNav');
      this.$store.dispatch('ui/doOpenSavedSearchesPanel');
      this.$router.replace('/account/saved-searches');
      this.navsShown['userMenu'] = false;
    },
    showAccountPanel(){
      if(!this.mapComponentPresent){
        window.location = '/account';
        return;
      }
      this.$store.commit('ui/hideNav');
      this.$store.dispatch('ui/doOpenAccountPanel');
      this.navsShown['userMenu'] = false;
    },
    showOverviewPanel(){
      if(!this.mapComponentPresent){
        window.location = '/account/overview';
        return;
      }
      this.$store.dispatch('ui/doOpenAccountPanel',"Overview");
      this.$store.commit('ui/hideNav');
      this.navsShown['userMenu'] = false;
      this.$router.replace('/account/overview');
    },
    scrollOnNavEvent(e){
    },
    async logout(){
      if(this.external){
        window.location = '/logout';
        return;
      }
      await this.$auth.logout();
      await this.$store.dispatch('user/logout');
    },
    doShowLogin(){
      if(this.external){
        window.location = '/listings?show_sign_in_modal=1';
        return;
      }
      this.$store.commit('ui/setShowLogin',true);
    },
    closeSearch(){
      this.searchText = null;
      this.searchResults = null;
      this.regionResults = null;
    },
    setActiveRegionProxy(region,types = null){
      this.searchResults = null;
      this.regionResults = null;
      this.setActiveRegion(region,types);
      this.searchText = null;
      this.regionResults = null;
      this.searchResults = null;
    },
    hideContactOverlay(){
      this.showContactOverlay = false;
    },
    async searchForResult(e){
      if(this.searchText && this.searchText.length >= 3) {
        const result = await axios.get('/api/v3/search/regions/search',{
          params:
            {
              s:this.searchText,
            }
        });
        if(result && result.data){
          this.regionResults = result.data;
        }else{
          this.regionResults = null;
        }
        if(!this.regionResults || !this.regionResults.result || !this.regionResults.result.length){
          const {data} = await axios.get('/api/v3/search/query', {
            params: {
              s: this.searchText
            }
          })
          this.searchResults = data;
        }else{
          this.searchResults = null;
        }
      }
    },
    closeCallOrText(){
      this.callOrTextShow = false
    },
    showNav(prop){

      return this.navsShown[prop];
    },
    showNavigation(){
      if(this.$store && this.$store.ui) {
        this.$store.commit('ui/doShowNav');
      }else{
        this.navsShown['userMenu'] = true;
        this.$forceUpdate();
      }
    },
    navClick(nav){
      if(nav.children){
        this.subNavsShown[nav.name] = true;
        this.$forceUpdate();
      }else{
      }
    },
    goNextListing(){
      this.$store.dispatch('listings/goNextListing');
    },
    goNextFavorite(){
      this.$store.dispatch('listings/goNextFavorite');
    },
    closeActiveListing() {
      this.$store.dispatch('listings/closeActiveListing');
      this.$store.dispatch('listings/updateUrl');
      this.$store.dispatch('listings/updateTitle');
    },
    doShowNav(prop){
      const keys = Object.keys(this.navsShown);
      for(var key of keys){
        this.navsShown[key] = false;
      }
      this.navsShown[prop] = true;
      this.$forceUpdate();
    },
    hideNav(prop){
      this.navsShown[prop] = false;
      this.$forceUpdate();
    },
    getBreadcrumbForValue(val,label){
      let parts = [];
      if(val.island) parts.push(val.island);
      if(val.district) parts.push(val.district);
      if(val.town) parts.push(val.town);
      if(parts.length > 1) return parts.join(" > ");
    },
  },
  computed:{
    userAttachedAgent() {
      return false;
    },
    showAdmin(){
      let roles = HL_NAV_USER_DATA && HL_NAV_USER_DATA.user && HL_NAV_USER_DATA.user.roles ? HL_NAV_USER_DATA.user.roles : [];
      if(roles.includes('admin')) return true;
      if(roles.includes('super_admin')) return true;
      return false;
    },
    showAgentAdmin(){
      let roles = HL_NAV_USER_DATA && HL_NAV_USER_DATA.user && HL_NAV_USER_DATA.user.roles ? HL_NAV_USER_DATA.user.roles : [];
      if(roles.includes('advisor')) return true;
      return false;
    },
    showUniversalSearchComponent(){
      //get the window location.. and if its / then dont show it
      if(window.location.pathname === '/') return false;
      if(window.location.pathname === '/agents') return false;
      return true;


    },
    agent(){
      console.log('we are grabbing an agent');
      return null;
    },
    first_name(){
      console.log('we are getting the first name');
      return null;
    },
    last_name(){
      console.log('we are getting the first name');
      return null;
    },
    slugAgent(){
      console.log('we are getting the slugAgent');
      return false;
    },
    mapComponentPresent() {
     return false;
    },
    nameContact(){

    },
    favoritesCount(){
      return this.$store.state.listings.favorites.length;
    },
    hiddenListingsExist(){

      return this.$store.state.listings && this.$store.state.listings.hiddenListings && this.$store.state.listings.hiddenListings.length;
    },
    activeListingDetails() {
      return this.$store.state.listings.activeListing;
    },
    agentDesignation(){
      if(this.agentOrSlugAgent && this.agentOrSlugAgent.designation){
        return this.agentOrSlugAgent.designation;
      }
    },
    savedSearches(){
      return this.$store.state.listings.savedSearches
    },
    savedSearchesExist(){
      return this.savedSearches && this.savedSearches.length;
    },
    activeSavedSearch(){
      return this.$store.state.listings.activeSavedSearch;
    },
    searchResultCount(){
      return this.$store.state.listings && this.$store.state.listings.listings ? this.$store.state.listings.listings.total : null;
    },
    activeListingIndex() {
      return this.$store.state.listings.activeListingIndex + 1;
    },
    loggedIn(){
      const hl_nav_data = window.HL_NAV_USER_DATA;
      if(hl_nav_data && hl_nav_data.user){
        return true;
      }else{
        return false;
      }


      return this.$store.state.user.loggedIn;
    },
    agentProfileImageURL(){
      if(this.agentOrSlugAgent) return this.agentOrSlugAgent.large_image_url;
      return null;

    },
    user(){
      let u = window.HL_NAV_USER_DATA;

      if(!u){
        return null;
      }else{
        return u.user;
      }
      const user = this.$store.state.user;
      return user;
    },
    agentOrSlugAgent(){
      if(window.HL_NAV_USER_DATA){
        if(window.HL_NAV_USER_DATA.advisor){
          return window.HL_NAV_USER_DATA.advisor;
        }
        if(window.HL_NAV_ADVISOR_SLUG_DATA && window.HL_NAV_ADVISOR_SLUG_DATA){
          return window.HL_NAV_ADVISOR_SLUG_DATA;
        }
      }

      if(window.HL_NAV_ADVISOR_SLUG_DATA){
        return window.HL_NAV_ADVISOR_SLUG_DATA;
      }
      if(this.agent && this.agent.email === 'customerexperience@hawaiilife.com') return null;
      if(this.agent) return this.agent;
      if(this.slugAgent) return this.slugAgent;
      if(process.browser) {
        if (localStorage.fetch('hawaiilife_referrer_agent')) {
          return localStorage.fetch('hawaiilife_referrer_agent');
        }
      }
    },
    filteredRegionResults(){
      let ret = {'exact_matches':[],'similar':[]};
      if(!this.regionResults || !this.regionResults.result || this.regionResults.result.length === 0) return ret;
      if(!this.regionResults || !this.searchText) return ret;
      for(var r of this.regionResults.result){
        if(r && r.name && r.name.toLowerCase() === this.searchText.toLowerCase().trim()){
          ret.exact_matches.push(r);
        }else{
          ret.similar.push(r);
        }
      }
      return ret;
    },
    mappedRegionResults(){
      if(!this.regionResults || !this.regionResults.result || this.regionResults.result.length === 0) return null;
      ////
      var {result} = this.regionResults;
      let mapped = {};
      for(var r of result){
        if(!mapped[r.region_level_string]) mapped[r.region_level_string] = [];
        mapped[r.region_level_string].push(r);
      }
      return mapped;
    },
    firstName(){
      let u = this.user && this.user.user ? this.user.user : this.user;
      if(u && u.first_name){
        return u.first_name;
      }else{
        return "Guest"
      }
    },
    agentFirstName(){
      if(this.agentOrSlugAgent) return this.agentOrSlugAgent.user_name;
      return "Your Agent";
    },
    lastName(){
      let u = this.user && this.user.user ? this.user.user : this.user;
      if(u && u.last_name){
        return u.last_name;
      }
    },
    initials(){
      if(this.firstName && this.lastName){
        return `${this.firstName[0]}${this.lastName[0]}`
      }
    },
  },
  data(){
    const defaultNav = {
      nav: {
        'Real Estate': [
          {href: '/listings', name: 'All Islands'},
          {href: '/listings/kauai', name: 'Kauai'},
          {href: '/listings/oahu', name: 'Oahu'},
          {href: '/listings/maui', name: 'Maui'},
          {href: '/listings/big-island', name: 'Big Island'},
          {href: '/listings/lanai', name: 'Lanai'},
          {href: '/listings/molokai', name: 'Molokai'},
          {href: '/developments', name: 'Developments'},
          {name:'Collections', children:[{'href':'/listings?collection=luxury',name:'Luxury'}]},
          {name:"Watch Video", href:'https://www.hawaiilife.com/video-channel/?ref=main-nav', class:'video-link'}
        ],
        'Rentals': [
          {href: 'https://vacations.hawaiilife.com/?utm_content=hawaiife.com', name: 'Vacation Rentals'},
          {href: 'https://www.hawaiilifelongtermrentals.com/all-vacancies', name: 'Long Term Rentals'},
          {href:'https://www.hawaiilife.com/company/long-term-rentals',name:'Property Management'}
        ],
        'News': [{href:'https://www.hawaiilife.com/blog',name:'Hawai\'i Life News'},
          {href:'https://vacations.hawaiilife.com/blog',name:'Vacations News'}
        ],
        'Company': [
          {href:'/agents',name:'Find An Agent'},
          {href:'/company/offices',name:'Find An Office'},
          {name:"About Hawaii Life >",children: [
                {href:'https://www.hawaiilife.com/company/',name:'The Company'},
                {href:'https://www.hawaiilife.com/company/team',name:'Leadership & Team'},
                {href:'https://www.hawaiilife.com/company/representation',name:'Buyers & Seller Services'},
                {href:'https://www.give.hawaiilife.com/',name:'Hawaii Life Charitable Fund'},
              ]},
          {name:"Specializations >",children:
              [
                {href:'https://www.hawaiilife.com/company/forbes-global-properties/',name:'Forbes Global Properties'},
                {href:'https://www.hawaiilife.com/company/military/',name:'Military'},
                {href:'https://www.hawaiilife.com/company/global-affiliations',name:'Global Affiliations'},
                {href:'https://www.hawaiilife.com/seniors',name:'Seniors'},
                {href:'https://www.hawaiilife.com/company/hawaii-life-japan/',name:'Japan and Asia'},
                {href:'https://www.hawaiilife.com/developments',name:'Developments'},
                {href:'https://www.hl1.com/',name:'HL1 Private Collection'},
                {href:'https://www.hawaiilife.com/company/conservation/',name:'Conservation'},
                {href:'https://www.hawaiilife.com/company/reo-bank-owned/',name:'REO Bank-Owned'},
              ]
          }



        ]
      }
    }
    if(this.agentOrSlugAgent){
      //delete(defaultNav.nav['Company'])
    }


      return {
      showSavedSearchMenu:false,
        external:true,
        contactAgentSender:null,
      showCircleCTA:false,
        contactAgentName:null,
        showFlashMessage:false,
      showContactAgentModal:false,
        contactAgent:{
          name:null,
          email:null,
          phone:null,
          message:null
        },
        errorAgentContactName:false,
        errorAgentContactEmail:false,
        errorAgentContactPhone:false,
        errorAgentContactMessage:false,
        contactAgentEmailValid:false,
        contactAgentId:829,
      navsShown:{},
        subNavsShown:{},
        searchText:null,
        callOrTextShow: false,
        showContactOverlay:false,
        regionResults:[],
        searchResults:[],
        defaultNav};

  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/main.scss';
@import '../assets/scss/TopNavigationUniversalComponent.scss';
@import '../assets/scss/variables/media';
.hl-modal{
  position:fixed;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  @media(max-width: $media-mobile){
    height:100svh;
  }
  background-color: rgba(255,255,255,0.5);
  display:flex;
  padding:40px;
  padding-top:10px;
  @media(max-width:768px){
    padding:0px;
    padding-top:20px;
  }
  &.no-padding{
    padding:0px;
  }
  &.full{
    padding:0px;
    padding-top:10px;
  }
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  overflow-y: scroll;
  .inner{

    box-shadow: rgba(50, 50, 93, 0.5) 0px 13px 27px -5px, rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
    margin-top:0px !important;
    background-color: white !important;
    height:auto;
    @media(max-width:768px){
      margin-top:20px !important;
    }
    &.no-padding{
      padding:0px;
    }
    &.small{
      max-height:30vh;
    }
    &.half{
      max-height:50vh;
    }
    &.three-fourths{
      max-height:75vh;
      @media(max-width: $media-mobile){
        max-height:90vh;
      }
    }
  }
  &.level-1{
    z-index:2000;
  }
  &.level-2{
    z-index: 2001;
  }
  &.level-3{
    z-index: 2002;
  }
  > div{
    min-width:500px;
    @media(max-width: 768px){
      min-width:100vw;
    }
  }
  .inner {
    height:auto;
    width:30vw;
    &.wide{
      width:50vw;
    }
    &.widest{
      width:80vw;
    }
    &.full{
      width:99vw;
    }
    &.almost-full{
      width:98vw;
    }
    &.half-height{
      height:50vh;
    }
    &.auto{
      width:auto;
    }
    &.tall{
      height:95vh;
      margin-top:60px;
      padding-top:50px;
    }
    &.half-height{
      height:auto;
      max-height:50vh;
    }
    padding:20px;
    position:relative;
    @media(max-width:768px){
      &.tall {
        padding-top: 0px;
      }
    }
    box-sizing: border-box;
    background-color: white;
    overflow-y: scroll;
    overflow-x:hidden;
    &.small {
      max-height: 30vh;
    }
  }

  .hl-modal{
    &.more-padding{
      .inner{
      }
    }
  }
}

.hl-modal {
  &.opaque{
    background-color: rgba(255,255,255,0.95);
  }
  &.fs-mobile{
    padding-top:0px;
    .inner{
      @media(max-width: $media-mobile){
        height:auto;
        max-height:100svh;
      }
    }
  }
}


.hl-modal.more-padding{
  .inner{
    padding:40px;
    @media(max-width: $media-mobile){
      padding:10px;
    }
  }
}

.hl-modal.fs-mobile{
  @media(max-width: $media-mobile){
    .inner {
      margin-top:-5px !important;
      max-height: 100svh;
      overflow-y: scroll;
    }
  }
}

.contact-agent{
  position:relative;
  background-color:white !important;
  height:auto;
  max-height:100svh;
  min-width:40vw;
  padding:20px;
  @media(max-width: $media-mobile){
    width:100vw;
    padding:20px;
    box-sizing: border-box;
  }

  &.external{
    div{
      input{
        color:red;
      }
    }
  }

}

.flash-message{
  position:fixed;
  top:50px;
  right:0px;
  z-index:10000;
  background-color: rgba(71, 176, 72, 0.9);
  height:auto;
  width:80vw;
  display:flex;
  flex-direction:row;
  align-items: center;
  padding:20px;
  transition: opacity 0.5s;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  text-align: center;
  font-family:HL-Whitney-Book,sans-serif;
  font-size:18px;
  color:white;
  @media(min-width: $media-mobile){
    max-width:600px;
    min-width:300px;
  }



  svg{
    margin-right:20px;
  }
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  &.error{
    background-color: red;
  }
}
.only-mobile{
  @media(min-width: $media-mobile){
    display:none;
  }
}








</style>
